import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <div>
        <SEO title="404: Not found" />

        <section className="container-fluid page-404 py-5">
          <div className="row">
            <div className="col-12 text-center">
              <figure className="error-img">
                <img src="/images/404/404.svg" alt="404" className="img-respfluidonsive" />
                <div className="compass-rotate"></div>
              </figure>

              <h5 className="my-4">
                Sorry, we couldn’t find this page. <br />
                You have boldly gone where no man has ever gone before.
              </h5>
              <Link to="/" className="btn btn-success">
                Go Back
              </Link>
            </div>
          </div>
        </section>
      </div>
    )
  )
}

export default NotFoundPage
